<template>
  <!-- Comment -->
    <b-card
      v-if="comments.length"
      body-class="pb-0"
    >
      <b-card-header class="p-0 pb-1 d-flex align-items-center">
        <h4>Komentar</h4>
      </b-card-header>
      <div class="pb-2">
        <template v-if="comments.length">
          <b-list-group>
            <b-list-group-item
              v-for="(comment, index) in comments"
              :key="comment.id"
              class="mb-1 border"
            >
              <span v-text="`# ${comments.length - index}`" />
              <span :style="{ padding: '0 5px' }">&bull;</span>
              <small v-text="moment(comment.created_at).format('DD MMMM YYYY')" />
              <hr class="my-1">
              <p
                class="mb-0 text-justify"
                v-text="comment.comment"
              />
            </b-list-group-item>
          </b-list-group>
        </template>
        <p
          v-else
          class="mb-0 text-center py-2"
        >
          Tidak ada komentar
        </p>
      </div>
    </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    BCard,
    BCardHeader,
    BListGroup,
    BListGroupItem,
  },
  props: {
    commentableId: [Number, String],
    commentable: String,
  },
  data() {
    return {
      comments: [],
      fetchCommentLoading: false,
    }
  },
  computed: {
    hasReviewed() {
      return this.comments.some(comment => comment.comment != 'Semua sudah benar')
    },
  },
  created() {
    this.fetchComments()
  },
  methods: {
    moment,
    fetchComments() {
      this.fetchCommentLoading = true
      useJwt.http.get(`comment/${this.commentable}/${this.commentableId}`)
        .then(response => this.comments = response.data.comments)
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => {
          this.fetchCommentLoading = false
          this.$emit('on-fetch', this.hasReviewed)
        })
    },
  }
}
</script>