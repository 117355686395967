<template>
  <!-- Comment -->
  <b-card>
    <b-card-header header-class="px-0 pt-0">
      <h4 class="mb-0">
        Komentar
      </h4>
    </b-card-header>

    <overlay-loading :loading="fetchCommentLoading">
      <b-row>
        <b-col
          v-show="$can('reviewer', 'tingkat 1')"
          class="mb-2"
          cols="12"
        >
          <b-form-checkbox v-model="noComment">
            Tidak ada komentar (semua sudah benar)
          </b-form-checkbox>
        </b-col>
        <b-col lg="4">
          <b-form
            class="mb-2 mb-lg-0"
            @submit.prevent="onComment('Perlu Diperbaiki')"
          >
            <b-form-textarea
              v-model="newComment"
              class="mb-1"
              rows="8"
              :disabled="noComment"
            />
            <p v-if="((!newComment && newComment == 'Semua sudah benar') || noComment)">
              Anda sudah menyatakan bahwa {{ commentable == 'DK' ? 'data-data di tabel ini' : 'isi dari laporan ini' }} <strong>sudah benar</strong>, sekarang anda sudah bisa mengganti status {{ commentable == 'DK' ? 'tabel' : 'laporan' }} ini menjadi <strong class="text-primary">Selesai</strong>.
            </p>
            <b-button
              v-else
              type="submit"
              variant="primary"
              size="sm"
              :disabled="commentLoading"
            >
              Kirim Komentar
            </b-button>
          </b-form>
        </b-col>
        <b-col lg="8">
          <template v-if="comments.length">
            <b-list-group>
              <overlay-loading
                v-for="(comment, index) in comments"
                :key="comment.id"
                :loading="deleteCommentLoadingId == comment.id"
              >
                <b-list-group-item class="mb-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bold mr-1" v-text="`# ${comments.length - index}`" />
                    </div>
                    <div class="d-flex align-items-center">
                      <feather-icon
                        v-show="comment.user_id == userId"
                        class="text-danger"
                        icon="TrashIcon"
                        size="15"
                        :style="{ cursor: 'pointer' }"
                        alt="Hapus komentar"
                        @click="onDeleteComment(comment.id)"
                      />
                      <span v-show="comment.user_id == userId" class="mx-1">|</span>
                      <small v-text="moment(comment.created_at).format('DD MMMM YYYY')" />
                    </div>
                  </div>
                  <hr class="my-1">
                  <p
                    class="mb-0 text-justify"
                    v-text="comment.comment"
                  />
                </b-list-group-item>
              </overlay-loading>
            </b-list-group>
          </template>
          <p
            v-else
            class="mb-0 text-center py-2"
          >
            Tidak ada komentar
          </p>
        </b-col>
      </b-row>
    </overlay-loading>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'

import OverlayLoading from '@views/components/OverlayLoading.vue'

import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    OverlayLoading,
  },
  props: {
    commentableId: [Number, String],
    commentable: String,
    status: String,
  },
  data() {
    return {
      comments: [],
      fetchCommentLoading: false,
      newComment: '',
      noComment: false,
      commentLoading: false,
      deleteCommentLoadingId: null,
      userId: getUserData().id
    }
  },
  computed: {
    hasReviewed() {
      return this.comments.some(comment => comment.comment != 'Semua sudah benar')
    },
  },
  watch: {
    noComment(value) {
      this.newComment = value ? 'Semua sudah benar' : ''
    },
  },
  created() {
    this.fetchComments()
  },
  methods: {
    moment,
    fetchComments() {
      this.fetchCommentLoading = true
      useJwt.http.get(`comment/${this.commentable}/${this.commentableId}`)
        .then(response => this.comments = response.data.comments)
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => {
          this.fetchCommentLoading = false
          this.$emit('on-fetch', this.hasReviewed)
        })
    },
    onComment(value) {
      console.log(`prevent value ${value}`)
      this.commentLoading = true
      useJwt.http.post('comment', { commentable_id: this.commentableId, commentable: this.commentable, comment: this.newComment })
        .then(response => {
          this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          this.comments.unshift(response.data.comment)

          if (this.newComment != 'Semua sudah benar') {
            this.newComment = ''
          }

          this.$emit('after-comment', value)
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.commentLoading = null)
    },
    onDeleteComment(id) {
      this.deleteCommentLoadingId = id
      useJwt.http.delete(`comment/${id}/0`)
        .then(response => {
          this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          this.comments = this.comments.filter(comment => comment.id != id)
        })
        .finally(() => this.deleteCommentLoadingId = null)
    },
  },
}
</script>
