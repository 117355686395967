<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        cols="6"
        class="d-flex align-items-center mb-0"
      >
        <!-- Page title -->
        <b-button
          variant="outline-secondary"
          @click="goToIndexPage()"
        >
          <feather-icon
            class="cursor-pointer"
            icon="ArrowLeftIcon"
          />
          <span class="d-none d-sm-inline-block">Kembali</span>
        </b-button>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-sm-end"
      >
        <div>
          <b-button
            v-show="!isViewOnly"
            class="mr-1 cursor-pointer"
            :variant="deletedIds.length <= 0 ? 'secondary' : 'danger'"
            :disabled="deletedIds.length <= 0"
            @click="showModal('delete-modal')"
          >
            <span>
              <strong>
                <feather-icon icon="TrashIcon" />
                Hapus Data
              </strong>
            </span>
          </b-button>
          <b-button
            v-show="!isViewOnly"
            class="mr-1"
            variant="success"
            @click="showModal('create-modal')"
          >
            <span>
              <strong>
                <feather-icon icon="FilePlusIcon" />
                Tambah Data
              </strong>
            </span>
          </b-button>
          <b-dropdown :variant="getStatusVariant(status)" right>
            <template #button-content>
              <span v-text="getStatus || 'Tidak ada status'" />
            </template>
            <b-dropdown-header id="dropdown-header-label">
              <small class="text-secondary">Pilih status</small>
            </b-dropdown-header>
            <b-dropdown-item
              variant="info"
              @click.prevent="() => {
                isOperator
                ? changeStatus(hasReviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau')
                : onUpdateStatus(hasReviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau')
              }"
            >
              {{ hasReviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau' }}
            </b-dropdown-item>
            <template v-if="isOperator">
              <b-dropdown-item
                variant="warning"
                @click.prevent="changeStatus('Proses Pengisian')"
              >
                Proses Pengisian
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="changeStatus('')">
                Tidak ada status
              </b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item
                variant="danger"
                @click.prevent="onUpdateStatus('Perlu Diperbaiki', 'trigger')"
              >
                Perlu Diperbaiki
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                @click.prevent="onUpdateStatus('Selesai')"
              >
                Selesai
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <b-card-header>
        <b-col lg="8">
          <h5 class="mb-0" v-text="tableTitle"></h5>
        </b-col>
      </b-card-header>

      <!-- Body -->
      <b-card-body class="overflow-x-scroll p-0">
        <overlay-loading :loading="fetchLoading">
          <table class="table table-hover table-bordered overflow-x-scroll" :style="{ minWidth: '100%' }">
            <thead>
              <tr v-for="(header, index) in headers">
                <th
                  v-if="index == 0 && !isViewOnly"
                  class="text-center align-middle"
                  :rowspan="headers.length"
                  :style="{ width: '10px' }"
                >
                  <b-form-checkbox
                    :checked="areAllDataWillBeDeleted"
                    @change="checked => setAllDataToBeDeleted(checked)"
                  />
                </th>
                <th
                  v-if="index == 0"
                  class="text-center align-middle"
                  :rowspan="headers.length"
                  :style="{ width: '10px' }"
                >
                  No
                </th>
                <th
                  v-for="_header in header"
                  v-text="_header.label"
                  v-bind="_header.attributes"
                  :style="{ ...((_header.attributes && _header.attributes.style) || {}), minWidth: '300px' }"
                  :class="`${('attributes' in _header && 'class' in _header.attributes) ? _header.attributes.class : ''} text-center align-middle`"
                />
              </tr>
            </thead>
            <tbody>
              <template v-if="data.length > 0">
                <tr v-for="(_data, index) in data" @dblclick="('disableEdit' in _data && _data.disableEdit) ? null : prepareEdit(_data)">
                  <td v-if="!isViewOnly" class="text-center" @dblclick="event => event.stopPropagation()">
                    <b-form-checkbox
                      :checked="deletedIds.includes(_data.id)"
                      @change="setDataToBeDeleted(_data.id)"
                    />
                  </td>
                  <td class="text-center" v-text="_data.number" />
                  <td
                    v-for="({ dataKey, render, dataAttributes = null }) in headerAttributes"
                    v-if="!resolveDataAttribute({ index, data: _data }, dataAttributes, 'hide', false)"
                    v-html="render(resolveHeaderDataKey(dataKey, _data), _data)"
                    v-bind="resolveDataAttribute({ index, data: _data }, dataAttributes)"
                  />
                </tr>
                <tr v-if="areThereHaveCalculatableData">
                  <td :colspan="totalCalculateColspan" :class="`align-middle ${totalCalculateColspan >= 3 ? 'text-right' : 'text-center'}`">
                    <strong>Total</strong>
                  </td>
                  <td
                    v-for="({ dataKey, calculate = false, calculateAttributes = null, calculateRender = null }) in headerAttributes"
                    v-if="!!dataKey && calculate"
                    v-bind="calculateAttributes"
                  >
                    <strong v-text="calculateRender ? calculateRender(calculateData(dataKey)) : calculateData(dataKey)"></strong>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td class="text-center py-5" :colspan="amountOfHeaders + 2">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </overlay-loading>
      </b-card-body>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="12"
            md="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            sm="12"
            md="2"
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Comment -->
    <OperatorComment
      v-if="isOperator"
      commentable="DK"
      :commentable-id="lkpsTableId"
      @on-fetch="hasReviewed => setReviewed(hasReviewed)"
    />
    <ReviewerComment
      v-else-if="isReviewer && ['Perlu Diperbaiki', 'Dapat Ditinjau', 'Dapat Ditinjau Ulang', 'Selesai'].includes(status)"
      ref="reviewerComment"
      commentable="DK"
      :commentable-id="lkpsTableId"
      :status="status"
      @on-fetch="hasReviewed => setReviewed(hasReviewed)"
      @after-comment="afterComment"
    />

    <!-- Create -->
    <b-modal
      v-if="!isViewOnly"
      id="create-modal"
      title="Tambah"
      body-class="py-2"
      hide-footer
      no-fade
      :no-enforce-focus="true"
      size="lg"
      @hidden="() => 'onClosedModal' in form && form.onClosedModal(form)"
    >
      <b-card-text>
        <validation-observer ref="createForm">
          <b-form @submit.prevent="onCreate">
            <b-row>
              <b-col
                v-for="input in form.inputs"
                :key="input.uniqueId"
                :cols="'col' in input ? input.col : '12'"
              >
                <b-form-group
                  :key="input.uniqueId"
                  :label="input.label"
                  :label-for="input.uniqueId"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="input.label"
                    :vid="input.uniqueId"
                    :rules="'rules' in input ? input.rules : null"
                  >
                    <b-form-input
                      v-if="input.type == 'text'"
                      :ref="`form-create-${input.uniqueId}`"
                      :id="input.uniqueId"
                      :type="input.type"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      v-model="formData.create[input.name]"
                      :state="errors.length > 0 ? false : null"
                      @focus="event => event.target.select()"
                      @input="'onChange' in input ? event => handleChange(input, event.target.value) : null"
                    />
                    <b-form-textarea
                      v-else-if="input.type == 'textarea'"
                      :ref="`form-create-${input.uniqueId}`"
                      :id="input.uniqueId"
                      :type="input.type"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      v-model="formData.create[input.name]"
                      :state="errors.length > 0 ? false : null"
                      @focus="event => event.target.select()"
                      @input="'onChange' in input ? event => handleChange(input, event.target.value) : null"
                    />
                    <b-form-input
                      v-else-if="input.type == 'number'"
                      :ref="`form-create-${input.uniqueId}`"
                      :id="input.uniqueId"
                      :type="input.type"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      v-model="formData.create[input.name]"
                      :state="errors.length > 0 ? false : null"
                      @focus="event => event.target.select()"
                      @change="'onChange' in input ? event => handleChange(input, event.target.value) : null"
                      step="any"
                    />
                    <flat-pickr
                      v-else-if="input.type == 'date'"
                      :ref="`form-create-${input.uniqueId}`"
                      :id="input.uniqueId"
                      class="form-control"
                      static="true"
                      v-model="formData.create[input.name]"
                      :state="errors.length > 0 ? false : null"
                      :config="flatPickrConfig('flatPickrConfig' in input ? input.flatPickrConfig : {})"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                    />
                    <currency-input
                      v-else-if="input.type == 'currency'"
                      :id="input.uniqueId"
                      v-model="formData.create[input.name]"
                      :state="errors.length > 0 ? false : null"
                      :options="currencyConfig('currencyConfig' in input ? input.currencyConfig : {})"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                    />
                    <v-select
                      v-else-if="input.type == 'select'"
                      :id="input.uniqueId"
                      :ref="input.uniqueId"
                      v-model="formData.create[input.name]"
                      :options="input.options"
                      :reduce="value => handleSelectValue(value, input)"
                      @search="(search, loading) => fetchOptions(search, loading, input)"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      :multiple="'multiple' in input ? !!input.multiple : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Save User -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="createLoading"
                v-text="createLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Edit -->
    <b-modal
      v-if="!isViewOnly"
      id="edit-modal"
      title="Edit"
      body-class="py-2"
      hide-footer
      no-fade
      :no-enforce-focus="true"
      size="lg"
      @hidden="() => 'onClosedModal' in form && form.onClosedModal(form)"
    >
      <b-card-text>
        <validation-observer ref="editForm">
          <b-form @submit.prevent="onEdit">
            <b-row>
              <b-col
                v-for="input in form.inputs"
                :key="input.uniqueId"
                :cols="'col' in input ? input.col : '12'"
              >
                <b-form-group
                  :label="input.label"
                  :label-for="input.uniqueId"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="input.label"
                    :vid="input.uniqueId"
                    :rules="'rules' in input ? input.rules : null"
                  >
                    <b-form-input
                      v-if="input.type == 'text'"
                      :ref="`form-edit-${input.uniqueId}`"
                      :id="input.uniqueId"
                      :type="input.type"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      v-model="formData.edit[input.name]"
                      :state="errors.length > 0 ? false : null"
                      @focus="event => event.target.select()"
                      @input="'onChange' in input ? event => handleChange(input, event.target.value) : null"
                    />
                    <b-form-textarea
                      v-else-if="input.type == 'textarea'"
                      :ref="`form-edit-${input.uniqueId}`"
                      :id="input.uniqueId"
                      :type="input.type"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      v-model="formData.edit[input.name]"
                      :state="errors.length > 0 ? false : null"
                      @focus="event => event.target.select()"
                      @input="'onChange' in input ? event => handleChange(input, event.target.value) : null"
                    />
                    <b-form-input
                      v-else-if="input.type == 'number'"
                      :ref="`form-create-${input.uniqueId}`"
                      :id="input.uniqueId"
                      :type="input.type"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      v-model="formData.edit[input.name]"
                      :state="errors.length > 0 ? false : null"
                      @focus="event => event.target.select()"
                      @change="'onChange' in input ? event => handleChange(input, event.target.value) : null"
                      step="any"
                    />
                    <flat-pickr
                      v-else-if="input.type == 'date'"
                      :ref="`form-edit-${input.uniqueId}`"
                      :id="input.uniqueId"
                      class="form-control"
                      static="true"
                      v-model="formData.edit[input.name]"
                      :state="errors.length > 0 ? false : null"
                      :config="flatPickrConfig('flatPickrConfig' in input ? input.flatPickrConfig : {})"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                    />
                    <currency-input
                      v-else-if="input.type == 'currency'"
                      :id="input.uniqueId"
                      v-model="formData.edit[input.name]"
                      :state="errors.length > 0 ? false : null"
                      :options="currencyConfig('currencyConfig' in input ? input.currencyConfig : {})"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                    />
                    <v-select
                      v-else-if="input.type == 'select'"
                      :id="input.uniqueId"
                      :ref="input.uniqueId"
                      v-model="formData.edit[input.name]"
                      :options="input.options"
                      :reduce="value => handleSelectValue(value, input)"
                      @search="(search, loading) => fetchOptions(search, loading, input)"
                      :placeholder="'placeholder' in input ? input.placeholder : null"
                      :multiple="'multiple' in input ? !!input.multiple : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Save User -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="editLoading"
                v-text="editLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Hapus -->
    <b-modal
      v-if="!isViewOnly"
      id="delete-modal"
      title="Hapus"
      cancel-variant="secondary"
      cancel-title="Tidak"
      ok-title="Iya"
      ok-variant="danger"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      no-fade
      @ok="onDelete"
      @shown="focusOnFirstInput('create')"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">
          Hapus data?
        </h4>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTextarea,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
  BDropdown,
  BDropdownHeader,
  BDropdownItem,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'

import OverlayLoading from '@views/components/OverlayLoading.vue'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { VueAutosuggest } from 'vue-autosuggest'
import { v4 } from 'uuid'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import CurrencyInput from '@views/pages/components/CurrencyInput.vue'
import { isPromise } from '@/libs/utils'

import OperatorComment from '@views/pages/components/operator/Comment.vue'
import ReviewerComment from '@views/pages/components/reviewer/Comment.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTextarea,
    BButton,
    BPagination,
    BFormSelect,
    BModal,
    BDropdown,
    BDropdownHeader,
    BDropdownItem,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    OverlayLoading,
    BForm,
    BFormGroup,
    BButton,
    VSelect,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
    Indonesian,
    CurrencyInput,
    OperatorComment,
    ReviewerComment,
  },
  props: {
    apiEndpoint: String,
    customResponseData: {
      type: Function,
      required: false,
      default: null
    },
    headers: Array,
    form: {
      type: Object,
      required: false,
      default: () => ({
        inputs: []
      })
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: null
    },
    singleExport: {
      type: [String, Boolean],
      required: false,
      default: false
    }
  },
  watch: {
    'pagination.currentPage': function () {
      this.fetch()
    },
    'pagination.selectedAmount': function () {
      this.fetch()
    },
  },
  computed: {
    getStatus() {
      if (this.status.includes('Dapat Ditinjau') && this.hasReviewed) {
        return 'Dapat Ditinjau Ulang'
      }
      return this.status
    },
    isReviewer() {
      return this.$can('reviewer', 'tingkat 1') || this.$can('reviewer', 'tingkat 2')
    },
    isOperator() {
      return this.$can('manage', 'operator')
    },
    isViewOnly() {
      if (this.viewOnly == null) {
        return this.isReviewer
      }
      return this.viewOnly
    },
    orderedDataKeyableHeaders() {
      const flattedHeaders = this.headers.flat().filter(header => 'dataKey' in header)
      if (flattedHeaders.some(header => 'order' in header)) {
        const headers = [...flattedHeaders]
        headers.sort((a, b) => a.order - b.order)
        return headers
      }
      return flattedHeaders
    },
    status() {
      return 'status' in this.dataMaster ? this.dataMaster.status : ''
    },
    tableTitle() {
      return 'list_lkps_table' in this.dataMaster ? this.dataMaster.list_lkps_table.title : ''
    },
    getStatusVariant() {
      return status => this.$variantDropDown[status]
    },
    headerAttributes() {
      const results = []
      for (let i = 0; i < this.orderedDataKeyableHeaders.length; i++) {
        const header = this.orderedDataKeyableHeaders[i]
        results.push({
          ...header,
          render: 'render' in header
            ? header.render
            : (data, _) => data,
        })
      }
      return results
    },
    amountOfHeaders() {
      let results = 0
      for (let i = 0; i < this.headers.length; i++) {
        for (let j = 0; j < this.headers[i].length; j++) {
          results += 1
        }
      }
      return results
    },
    areAllDataWillBeDeleted() {
      if (this.deletedIds.length <= 0) return false
      for (let i = 0; i < this.data.length; i++) {
        if (!this.deletedIds.includes(this.data[i].id)) {
          return false
        }
      }
      return true
    },
    areThereHaveCalculatableData() {
      return this.calculation && this.headerAttributes.some(header => 'calculate' in header)
    },
    totalCalculateColspan() {
      return (this.headerAttributes.length - this.headerAttributes.filter(header => 'calculate' in header).length) + 2
    },
    flatPickrConfig() {
      return config => ({
        wrap: true,
        altInput: true,
        dateFormat: 'd-m-Y',
        altFormat: 'd-m-Y',
        ariaDateFormat: 'd-m-Y',
        locale: Indonesian,
        defaultDate: 'today',
        ...config
      })
    },
    currencyConfig() {
      return config => ({
        currency: 'IDR',
        locale: 'id-ID',
        currencyDisplay: 'hidden',
        autoDecimalDigits: false,
        precision: 2,
        ...config
      })
    }
  },
  created() {
    this.fetch()
    this.form.inputs = this.form.inputs.map(input => {
      const defaultValue = 'defaultValue' in input ? input.defaultValue : ''
      this.formData.create[input.name] = defaultValue
      this.formData.edit[input.name] = ''
      return { uniqueId: v4(), ...input }
    })
  },
  data() {
    return {
      lkpsTableId: parseInt(this.$route.params.lkps_table_id),
      dataMaster: {},
      title: '',
      data: [],
      calculation: null,
      showDeleteButton: false,
      deletedIds: [],
      fetchLoading: false,
      createLoading: false,
      editLoading: false,
      hasReviewed: false,
      formData: {
        create: {},
        edit: { id: null },
      },
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      timeoutDebounce: null,
      required,
    }
  },
  methods: {
    goToIndexPage() {
      this.$router.push({ name: this.isOperator ? 'op-lkps' : 'rev-lkps' })
    },
    setAllDataToBeDeleted(checked) {
      this.deletedIds = []
      if (checked) {
        this.data.forEach(data => this.deletedIds.push(data.id));
      }
    },
    setDataToBeDeleted(id) {
      if (this.deletedIds.includes(id)) {
        this.deletedIds = this.deletedIds.filter(deletedId => deletedId != id)
      } else {
        this.deletedIds.push(id)
      }
    },
    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    fetch() {
      this.fetchLoading = true

      const paramater = this.resolveFetchParameter({ pagination: this.pagination })
      let http = useJwt.http.get(paramater.url, {
        params: {
          ...paramater.query,
          lkps_table_id: this.lkpsTableId
        }
      })

      http.then(response => {
        // insert number to data for column 'No'
        let number = ((this.pagination.currentPage * this.pagination.selectedAmount) - this.pagination.selectedAmount) + 1

        // Using customResponseData
        if (this.customResponseData != null) {
          this.data = this.customResponseData(response.data[`${this.apiEndpoint}s`])
        } else {
          this.data = response.data[`${this.apiEndpoint}s`]
        }

        this.data = this.data.map(list => ({ ...list, number: number++ }))

        this.calculation = response.data.calculation
        this.dataMaster = response.data.dataMaster
        this.pagination.totalItems = response.data.total_items
      })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    resolveFetchParameter({ pagination }) {
      if (this.isOperator) {
        const userData = getUserData()
        const department = userData.departments.length ? userData.departments[0] : null
        const programStudi = department ? department.program_studi : ''
        const shortName = department ? department.lkps_program.short_name : ''
        const { ts } = userData.lkps_profile
  
        return {
          url: this.apiEndpoint,
          query: {
            page: pagination.currentPage - 1,
            size: pagination.selectedAmount,
            sort: 'DESC',
            ts,
            short_name: shortName,
            program_studi: programStudi,
          },
        }
      }
      
      const { ts, department: [programStudi, shortName] } = this.$store.state.app.reviewer
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: "DESC",
          ts,
          short_name: shortName,
          program_studi: programStudi,
        },
      }
    },
    focusOnFirstInput(createOrEdit) {
      this.$nextTick(() => {
        const inputRefNames = Object.keys(this.$refs).filter(refName => refName.includes(`form-${createOrEdit}`))
        if (inputRefNames.length) {
          const firstInputRefName = inputRefNames[0]
          if (firstInputRefName && this.$refs[firstInputRefName][0]) {
            if ('focus' in this.$refs[firstInputRefName][0]) {
              this.$refs[firstInputRefName][0].focus()
            }
          }
        }
      })
    },
    onCreate() {
      this.formData.create.lkps_table_id = parseInt(this.lkpsTableId)
      this.$refs.createForm.validate().then(success => {
        if (success) {
          this.createLoading = true
          useJwt.http.post(
            this.apiEndpoint,
            'customPayload' in this.form
            ? this.form.customPayload(this.formData.create)
            : this.formData.create
          )
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
              this.resetStatus()
              this.fetch()
              this.focusOnFirstInput('create')
            })
            .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.createLoading = false)
        }
      })
    },
    prepareEdit(data) {
      Object.keys(this.formData.edit).forEach(formName => {
        this.formData.edit.id = data.id
        const input = this.form.inputs.filter(input => input.name == formName)
        if (input[0]) {
          this.resolveInputDataKey(input[0], data)
            .then(value => {
              if (input[0].type == 'currency') {
                this.formData.edit[formName] = parseFloat(value)
              } else {
                this.formData.edit[formName] = value
              }
            })
            .finally(() => this.$forceUpdate())
        }
      })
      this.showModal('edit-modal')
      this.focusOnFirstInput('edit')
    },
    onEdit() {
      this.formData.edit.lkps_table_id = parseInt(this.lkpsTableId)
      this.$refs.editForm.validate().then(success => {
        if (success) {
          this.editLoading = true
          useJwt.http.put(
            `${this.apiEndpoint}/${this.formData.edit.id}`,
            'customPayload' in this.form
            ? this.form.customPayload(this.formData.edit)
            : this.formData.edit
          )
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
              this.resetStatus()
              this.$bvModal.hide('edit-modal')
              this.fetch()
            })
            .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.editLoading = false)
        }
      })
    },
    onDelete() {
      const deletedData = this.deletedIds.map(id => useJwt.http.delete(`${this.apiEndpoint}/${id}/0`))
      Promise.all(deletedData)
        .then(response => {
          this.$alert({ title: response[0].data.message, variant: 'success', icon: 'CheckIcon' })
          this.fetch()
          this.resetStatus()
          this.deletedIds = []
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
    },
    setReviewed(hasReviewed) {
      this.hasReviewed = hasReviewed
    },
    resetStatus() {
      if (this.status.includes('Dapat Ditinjau', 'Selesai', 'Perlu Diperbaiki')) {
        this.changeStatus('Proses Pengisian')
      }
    },
    changeStatus(status) {
      this.dataMaster.status = status

      const userData = getUserData()
      const department = userData.departments.length ? userData.departments[0] : null
      const programStudi = department ? department.program_studi : ''
      const shortName = department ? department.lkps_program.short_name : ''

      useJwt.http.put(`lkps_table/${this.dataMaster.id}`, {
        status: this.dataMaster.status,
        ts: this.dataMaster.ts,
        program_studi: programStudi,
        short_name: shortName,
      })
        .then(response => this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' }))
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))

      // this export mandatory lkps profile data
      if (this.singleExport && status.includes(this.singleExport)) {
        useJwt.http.get(`${this.dataMaster.list_lkps_table.helper_model}/submit/excel`, {
          params: {
            sort: 'ASC',
            lkps_table_id: `${this.dataMaster.id}`,
            ts: this.dataMaster.ts,
            detail_type: `${this.dataMaster.list_lkps_table.name_sheet}`,
          },
        })
          .then(response => this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' }))
          .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
      }
    },
    // For reviewer
    alertBeforeChangeStatus() {
      const statusOnChange = {
        'Selesai'          : 'Tabel belum ditandai dengan Semua Benar.',
        'Perlu Diperbaiki' : 'Isilah komentar hal yang perlu diperbaiki maka otomatis status berubah menjadi Perlu Diperbaiki.',
      }
      return any => statusOnChange[any]
    },
    // For reviewer
    afterComment(value) {
      console.log(`after comment ${value}`)
      this.onUpdateStatus(value, 'stopLooping')
    },
    // For reviewer
    onUpdateStatus(status, from) {
      if (status === 'Selesai' || (status === 'Perlu Diperbaiki' && from === 'trigger')) {
        if (!this.$refs.reviewerComment.noComment) {
          this.$alert({ title: this.alertBeforeChangeStatus(status), variant: 'danger', icon: 'XIcon' })
          console.log(`i am ${status} no action`)
          return
        }
        if (status === 'Perlu Diperbaiki') {
          this.$alert({ title: this.alertBeforeChangeStatus(status), variant: 'danger', icon: 'XIcon' })
          console.log(`i am ${status} - Perlu with no comment action`)
          return
        }
        if (status === 'Selesai') {
          if (from !== 'stopLooping') {
            console.log(`i am ${status} - Selesai with comment action`)
            this.$refs.reviewerComment.onComment(status)
            this.nextTickOfUpdate(status)
            return
          }
          return
        }
        console.log(`i am ${status} with comment action`)
      }
      this.nextTickOfUpdate(status)
    },
    // For reviewer
    nextTickOfUpdate(status) {
      const userData = getUserData()
      const department = userData.departments.length ? userData.departments[0] : null

      const programStudi = department ? department.program_studi : ''
      const shortName = department ? department.lkps_program.short_name : ''
      const jenisProgram = department ? department.lkps_program.jenis_program : ''
      const { ts } = this.$store.state.app.reviewer

      this.dataMaster.status = status
      useJwt.http.put(`lkps_table/${this.dataMaster.id}`, {
        status: this.dataMaster.status,
        ts,
        program_studi: programStudi,
        short_name: shortName,
        jenis_program: jenisProgram,
      })
        .then(response => this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' }))
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
    },
    resolveObjectKey(path, obj) {
      if (path) {
        return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj)
      }
      return ''
    },
    resolveHeaderDataKey(dataKey, data) {
      if (typeof dataKey == 'function') {
        return dataKey(data)
      }
      return this.resolveObjectKey(dataKey, data)
      
      // if (typeof header.dataKey == 'function') {
      //   // check if dataKey is async (promise)
      //   if (isPromise(header.dataKey)) {
      //     return await header.dataKey(data)
      //   }
      //   return header.dataKey(data)
      // }
    },
    async resolveInputDataKey(input, data) {
      if (typeof input.dataKey == 'function') {
        // check if dataKey is async (promise)
        if (isPromise(input.dataKey)) {
          return await input.dataKey(data, input)
        }
        return input.dataKey(data, input)
      }
      return this.resolveObjectKey(input.dataKey, data)
    },
    fetchOptions(search, loading, input) {
      loading(true)
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(async () => {
        if ('fetchOptions' in input) {
          let options = input.fetchOptions(search, loading)
          if (isPromise(options)) {
            const fetchedOptions = await options
            if (fetchedOptions) input.options = fetchedOptions
          } else {
            input.options = options
          }
        }
        loading(false)
      }, 300)
    },
    handleSelectValue(value, input) {
      if ('customSelectedValue' in input) {
        const result = input.customSelectedValue(value, this.formData)
        this.$forceUpdate()
        return result
      }
      return value
    },
    calculateData(dataKey) {
      if (this.calculation) {
        return this.calculation[dataKey]
      }
    },
    handleChange(input, value) {
      alert()
      if ('onChange' in input) {
        input.onChange(value, this.formData)
        this.$forceUpdate()
      }
    },
    resolveDataAttribute(passedData, dataAttributes, key = null, defaultValue = null) {
      if (dataAttributes) {
        let attributes
        if (typeof dataAttributes == 'function') {
          attributes = dataAttributes({ vm: this, ...passedData })
        } else {
          attributes = dataAttributes
        }

        if (key) {
          if (attributes[key]) return attributes[key]
          return defaultValue
        }
        return attributes
      }
      return null
    }
  }
}
</script>

<style scoped>
  table td, table th {
    border-width: 2px;
    border-color: rgb(230, 230, 230);
  }

  table#table-transition-gjmf .flip-list-move {
    transition: transform 0.4s;
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
    
  table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }

  .bg-white {
    background-color: white !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .select-none {
    user-select: none;
  }

  table * {
    user-select: none;
  }

  .overflow-x-scroll {
    overflow-x: scroll;
    overflow-y: visible;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss'
</style>
